import React from 'react';
require('dotenv').config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const etherscanAPIKey = process.env.REACT_APP_ETHERSCAN_API_KEY;

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const axios = require('axios');

const web3 = createAlchemyWeb3(alchemyKey);

export const auctionContractABI = require('../auction-abi.json')
export const auctionContractAddress = "0xF17fE4951a863E728836b9bF9DCc9416cf41C858";
export const mintingContractABI = require('../exclusives-abi.json')
export const mintingContractAddress = "0x04A2b373938bF88C33Cf2E4A21f911E368d3B198";

// Define a function that takes the contract address, start block and end block as parameters and returns a promise of an array of transaction hashes
function getContractTransactions(startBlock, endBlock) {
  // Build the API url with the required parameters
  let url = `https://api.etherscan.io/api?module=account&action=txlist&address=${auctionContractAddress}&startblock=${startBlock}&endblock=${endBlock}&sort=asc&apikey=${etherscanAPIKey}`;
  // Make a GET request to the API and handle the response and error
  return axios.get(url)
    .then(response => { 
      // Check if the response status is 200 and the result status is 1 (success)
      if (response.status === 200 && response.data.status === '1') {
        // Extract the result array from the response data
        let result = response.data.result;
        // Initialize an empty array to store the transaction hashes
        let hashes = [];
        // Loop through the result array and filter the valid transactions
        for (let tx of result) {
          // Check if the transaction has a non-zero value, a non-empty input, a success status and a to address matching the contract address
          
          if (tx.value !== '0' && tx.input !== '0x' && tx.isError === '0' && tx.to.toLowerCase() === auctionContractAddress.toLowerCase()) {
            // Push the transaction hash to the hashes array
            hashes.push([tx.hash, tx.value]);
          }
        }
        // Return the hashes array as the resolved value of the promise
        return hashes;
      } else {
        // If the response status is not 200 or the result status is not 1, throw an error with the message from the response data
        throw new Error(response.data.message);
      }
    })
    .catch(error => {});
}

// Define a function that takes a unix time in seconds and an element as parameters
export function displayCountdown(unixTime, elementID) {
  const element = document.getElementById(elementID);
  // Get the current time in seconds
  var now = Math.floor(Date.now() / 1000);
  // Calculate the remaining time in seconds
  var remaining = unixTime - now;
  // If the remaining time is positive, update the element with the formatted time
  if (remaining > 0) {
    // Convert the remaining time to hours, minutes, and seconds
    var hours = Math.floor(remaining / 3600);
    var minutes = Math.floor((remaining % 3600) / 60);
    var seconds = remaining % 60;
    // Pad the numbers with zeros if needed
    var hoursStr = hours < 10 ? "0" + hours : "" + hours;
    var minutesStr = minutes < 10 ? "0" + minutes : "" + minutes;
    var secondsStr = seconds < 10 ? "0" + seconds : "" + seconds;
    // Create a string with the format hh:mm:ss
    var timeStr = hoursStr + ":" + minutesStr + ":" + secondsStr;
    // Set the element's text content to the time string
    element.textContent = 'Time Remaining: ' + timeStr;
    // Call the function again after one second using setTimeout
    setTimeout(function() {
      displayCountdown(unixTime, elementID);
    }, 1000);
  } else {
    // If the remaining time is zero or negative, set the element's text content to "Time's up!"
    element.textContent = "Auction is Closed";
    let input = document.getElementById('amountToBid')
    let button = document.getElementById('placeBid')
    input.style.display = 'none';
    button.style.display = 'none';
  }
}

const updatePlaceholder = async() => {
  let input = document.getElementById('amountToBid')
  let nextBid = await getNextBid()
  input.setAttribute('placeholder', nextBid + ' Ξ');
}

export const getAuctionDetails = async (auctionId) => {
  try {
    window.contract = new web3.eth.Contract(auctionContractABI, auctionContractAddress);
    let auctionDetails = web3.eth.call({
    to: auctionContractAddress,
    data: window.contract.methods.getReserveAuction(auctionId).encodeABI()
    }).then(data => auctionDetails = data);
    return web3.eth.abi.decodeParameters(auctionContractABI[21].outputs, await auctionDetails)[0]
  } catch (error) {}
}

export const placeBid = async () => {
  let auctionId = await getCurrentAuctionId()
  let bidAmount = document.getElementById('amountToBid').value
  if (bidAmount == '') {
    bidAmount = await getNextBid()
  }
  window.contract = new web3.eth.Contract(auctionContractABI, auctionContractAddress);
  const transactionParameters = {
    to: auctionContractAddress,
    from: window.ethereum.selectedAddress,
    value: "0x" + web3.utils.toBN(web3.utils.toWei(bidAmount.toString(), "ether")).toString(16),
    data: window.contract.methods.placeBid(auctionId).encodeABI()};
  await window.ethereum
  .request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
  });
}

export const getNextBid = async () => {
  let auctionId = await getCurrentAuctionId()
  try {
    let nextBid = web3.utils.fromWei((await getAuctionDetails(auctionId)).amount, 'ether')
    return Math.ceil(1000*(nextBid * 1.1))/1000
  } catch(error) {}
}

export const getCurrentAuctionId = async () => {
  try {
    window.contract = new web3.eth.Contract(auctionContractABI, auctionContractAddress);
    let nextAuctionId = web3.eth.call({
    to: auctionContractAddress,
    data: window.contract.methods.getCurrentAuctionId().encodeABI()
    }).then(data => nextAuctionId = data);
    return web3.eth.abi.decodeParameters(auctionContractABI[20].outputs, await nextAuctionId)[0]
  } catch (error) {}
}

export const getTokenDetails = async (tokenId) => {
  try {
    window.contract = new web3.eth.Contract(mintingContractABI, mintingContractAddress);
    let details = web3.eth.call({
    to: mintingContractAddress,
    data: window.contract.methods.getDetails(tokenId).encodeABI()
    }).then(data => details = data);
    return web3.eth.abi.decodeParameters(mintingContractABI[28].outputs, await details)
  } catch (error) {}
}

export async function createDetailsTable(tokenId) {
  let table = document.getElementById('details');
  let tokenDetails = await getTokenDetails(tokenId)
  let link1 = document.createElement('a'); // create a new anchor element for the contract address
  link1.href = 'https://etherscan.io/address/' + mintingContractAddress; // set the href attribute to the contract address
  link1.target = '_blank'; // set the target attribute to open in a new tab
  link1.textContent = mintingContractAddress.substring(0, 6)  + "..." + mintingContractAddress.substring(38); // set the text content to the truncated address
  let row1 = document.createElement('tr'); // create a new table row element for the contract address
  let cell1 = document.createElement('td'); // create a new table cell element for the label
  cell1.textContent = 'Contract'
  row1.appendChild(cell1);
  let cell2 = document.createElement('td'); // create a new table cell element for the value
  cell2.appendChild(link1); 
  row1.appendChild(cell2); 
  table.appendChild(row1);

  let link2 = document.createElement('a'); // create a new anchor element for the token ID
  link2.href = 'https://etherscan.io/token/' + mintingContractAddress + '?a=' + tokenId; // set the href attribute to the token page
  link2.target = '_blank'; // set the target attribute to open in a new tab
  link2.textContent = tokenId; // set the text content to the token ID
  let row2 = document.createElement('tr'); // create a new table row element for the token ID
  let cell3 = document.createElement('td'); // create a new table cell element for the label
  cell3.textContent = 'Token ID'
  row2.appendChild(cell3);
  let cell4 = document.createElement('td'); // create a new table cell element for the value
  cell4.appendChild(link2); 
  row2.appendChild(cell4); 
  table.appendChild(row2);

  let link3 = document.createElement('a'); // create a new anchor element for the metadata
  link3.href = 'https://ipfs.io/ipfs/' + tokenDetails.metadataCID; // set the href attribute to the metadata IPFS link
  link3.target = '_blank'; // set the target attribute to open in a new tab
  link3.textContent = 'IPFS'; // set the text content to the metadata CID
  let row3 = document.createElement('tr'); // create a new table row element for the metadata
  let cell5 = document.createElement('td'); // create a new table cell element for the label
  cell5.textContent = 'Metadata'
  row3.appendChild(cell5);
  let cell6 = document.createElement('td'); // create a new table cell element for the value
  cell6.appendChild(link3); 
  row3.appendChild(cell6); 
  table.appendChild(row3);

  let link4 = document.createElement('a'); // create a new anchor element for the metadata
  link4.href = 'https://twitter.com/ahbap';
  link4.target = '_blank'; // set the target attribute to open in a new tab
  link4.textContent = 'Ahbap';
  let row4 = document.createElement('tr'); // create a new table row element for the metadata
  let cell7 = document.createElement('td'); // create a new table cell element for the label
  cell7.textContent = 'Supporting'
  row4.appendChild(cell7);
  let cell8 = document.createElement('td'); // create a new table cell element for the value
  cell8.appendChild(link4); 
  row4.appendChild(cell8); 
  table.appendChild(row4);
}

async function createHyperlink(address, amount, timeAgo, txns, length) {
  let table = document.getElementById('bids');  
  if (txns != undefined) {
    for (let i = txns.length - length; i < txns.length; i++) {
      if (txns[i][1] == amount) {
        let ethAmount = web3.utils.fromWei(amount, 'ether') // convert the bid amount to ether
        let link1 = document.createElement('a'); // create a new anchor element for the address
        link1.href = 'https://opensea.io/' + address; // set the href attribute to the address
        link1.target = '_blank'; // set the target attribute to open in a new tab
        link1.textContent = address.substring(0, 6); // set the text content to the truncated address
        let link2 = document.createElement('a'); // create a new anchor element for the transaction
        link2.href = 'https://etherscan.io/tx/' + txns[i][0]; // set the href attribute to the transaction
        link2.target = '_blank'; // set the target attribute to open in a new tab
        link2.textContent = timeAgo; // set the text content to the time
        let row = document.createElement('tr'); // create a new table row element
        let cell1 = document.createElement('td'); // create a new table cell element for the address
        cell1.style.whiteSpace = 'nowrap'; // add this line to prevent wrapping the address
        cell1.appendChild(link1); 
        row.appendChild(cell1);
        let cell2 = document.createElement('td'); // create a new table cell element for the amount
        cell2.textContent = ethAmount + 'Ξ'; // set the text content to the amount
        row.appendChild(cell2); 
        let cell3 = document.createElement('td'); // create a new table cell element for the time
        cell3.style.whiteSpace = 'nowrap'; // add this line to prevent wrapping the time
        cell3.appendChild(link2); 
        row.appendChild(cell3);
        table.appendChild(row);
      }
    }
  }
}

function formatTimeAgo(unixTime) {
  // Get the current unix time in seconds
  const now = Math.floor(Date.now() / 1000);
  // Calculate the difference in seconds
  const diff = now - unixTime;
  // If the difference is less than 60 seconds, return "just now"
  if (diff < 60) {
    return "just now";
  }
  // If the difference is less than an hour, return the number of minutes ago
  if (diff < 3600) {
    const minutes = Math.floor(diff / 60);
    return minutes + " min" + (minutes === 1 ? "" : "s") + " ago";
  }
  // Otherwise, return the number of hours ago
  const hours = Math.floor(diff / 3600);
  return hours + " hour" + (hours === 1 ? "" : "s") + " ago";
}

export const getBids = async () => {
  try {
    await updatePlaceholder();
    document.getElementById('bids').innerHTML = '';
    let auctionId = await getCurrentAuctionId()
    let details = await getAuctionDetails(auctionId)
    let txns = await getContractTransactions(0, details.endTime)
    window.contract = new web3.eth.Contract(auctionContractABI, auctionContractAddress);
    let bids = web3.eth.call({
    to: auctionContractAddress,
    data: window.contract.methods.getAllBids(auctionId).encodeABI()
    }).then(data => bids = data);
    let array = web3.eth.abi.decodeParameters(auctionContractABI[19].outputs, await bids)[0] //getAllBids
    if (array.amount.length != 0) {
      let existingBids = document.getElementById('bids').querySelectorAll('a')
      if (existingBids.length === 0) {
        let reversedAmountArray = array.amount.slice().reverse(); // create a copy and flip the order of the bids
        let reversedBidderArray = array.bidder.slice().reverse();
        let reversedTimestampArray = array.timestamp.slice().reverse();
        for (let i = 0; i < reversedAmountArray.length; i++) {
          let timeAgo = formatTimeAgo(reversedTimestampArray[i])
          let bidAmount = reversedAmountArray[i]; // get the bid object
          let bidder = reversedBidderArray[i];
          try {
            let link = await createHyperlink(bidder, bidAmount, timeAgo, txns, array.amount.length); // create an anchor element with the bidder's address
            document.getElementById('bids').appendChild(link); // append the link to the bids element
          } catch (error) {}
        }
      }
    }
  } catch (error) {}
}


export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" rel="noopener noreferrer" href={`https://metamask.io/download.html`} >
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "",
          };
        } else {
          return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };