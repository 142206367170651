import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import { connectWallet, getCurrentWalletConnected, getBids, placeBid, displayCountdown, getAuctionDetails, getCurrentAuctionId, createDetailsTable } from "./../../utils/interact.js";
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  async function onLoad() {
    try {
      await getBids();
      let auctionDetails = await getAuctionDetails(await getCurrentAuctionId())
      let endTime = auctionDetails.endTime
      await createDetailsTable(auctionDetails.tokenId);
      displayCountdown(endTime, 'countdown');
    } catch (error) {}
  }

  window.onload = onLoad;


  const [walletAddress, setWallet] = useState("")
  const [status, setStatus] = useState("")

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" rel="noopener noreferrer" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }
  
  useEffect(async () => {
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address)
    setStatus(status);
    addWalletListener();
}, [])

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  }

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  Details
                </p>
                <div id="details"></div>
              </div>
            </div>
  
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                    Latest Bids
                  </p>
                  <div id="bids"></div>
                  <br></br>
                </div>
              </div>
            </div>
  
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="container-xs" style={{ textAlign: "left" }}>
                  <div
                    className="reveal-from-bottom"
                    data-reveal-delay="600"
                    style={{ textAlign: "right" }}
                  >
                    <p id="countdown"> </p>
                    <Button id="walletButton" onClick={connectWalletPressed}>
                      {walletAddress.length > 0 ? (
                        "Connected: " +
                        String(walletAddress).substring(0, 6) +
                        "..." +
                        String(walletAddress).substring(38)
                      ) : (
                        <span>Connect Wallet</span>
                      )}
                    </Button>
                    <br></br>
                    <br></br>
                    <Button id="reload" onClick={getBids} className="btn walletButton">
                      Refresh Bids
                    </Button>
                    <br></br>
                    <br></br>
                    <input
                      id="amountToBid"
                      type="text"
                      placeholder = ''
                      style={{ width: "125px", height: 40 }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      id="placeBid"
                      onClick={placeBid}
                      className="btn walletButton"
                    >
                      Place Bid
                    </Button>
                    <br></br>
                    <br></br>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;